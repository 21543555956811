var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-2" },
    [
      _c("v-card-title", [
        _c("div", {
          style: _vm.styles.title,
          attrs: { id: "title" },
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
      ]),
      _c("v-card-text", { style: _vm.styles.body, attrs: { id: "body" } }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.body) } }),
        _c("div", { staticClass: "d-flex", attrs: { id: "profile" } }, [
          _c(
            "div",
            { attrs: { id: "avatar" } },
            [
              _c(
                "v-avatar",
                { attrs: { size: _vm.$vuetify.breakpoint.forPC ? 100 : 70 } },
                [_c("img", { attrs: { src: _vm.avatarSrc } })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { style: _vm.styles.affiliation, attrs: { id: "affiliation" } },
            [
              _vm.logoSrc
                ? _c("div", { style: _vm.styles.logo, attrs: { id: "logo" } }, [
                    _c("img", { attrs: { src: _vm.logoSrc } }),
                  ])
                : _vm._e(),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.affiliation) } }),
              _c("div", {
                attrs: { id: "name" },
                domProps: { innerHTML: _vm._s(_vm.name) },
              }),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }