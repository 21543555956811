var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { staticClass: "grey lighten-4" },
    [
      _c("page-section-title", { attrs: { title: "お知らせ" } }),
      _c("news-card", {
        attrs: {
          date: "2022/9/20",
          tag: "event",
          title: "第6回 AI・人工知能EXPO【秋】のブース出展に参加いたします",
          body: "2022年10月26日（水）〜28日（金）に東京ビッグサイトで開催される第6回 AI・人工知能EXPO【秋】において、\n              ㈱知能フレームワーク研究所ブース（小間番号：13-20）内にて、Tuttiの展示・デモを行います。是非お越しください。\n              （イベント公式ページは<a href='https://www.nextech-week.jp/autumn/ja-jp/about/ai.html' target='_blank'>こちら</a>）",
        },
      }),
      _c("news-card", {
        attrs: {
          date: "2022/5/10",
          tag: "event",
          title: "第6回 AI・人工知能EXPO【春】のブース出展に参加いたします",
          body: "2022年5月11日（水）〜13日（金）に東京ビッグサイトで開催される第6回 AI・人工知能EXPO【春】において、\n              弊社パートナー企業である株式会社エキュメノポリスのブース内にて、弊社プロダクトである\n              Tutti、Scattの展示・説明を行います。是非お越しください。\n              （イベント公式ページは<a href='https://www.nextech-week.jp/spring/ja-jp/about/ai.html' target='_blank'>こちら</a>）",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }