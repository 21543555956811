var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    [
      _c("page-section-title", { attrs: { title: "導入機関" } }),
      _c(
        "div",
        _vm._l(_vm.logos, function (logo, i) {
          return _c(
            "div",
            { key: "customer-logo-" + i, attrs: { id: "logo-wrapper" } },
            [
              logo.type === "text"
                ? _c("p", { staticClass: "logo-text" }, [
                    _vm._v(_vm._s(logo.src)),
                  ])
                : _vm._e(),
              logo.type === "img"
                ? _c("img", {
                    staticClass: "logo",
                    style: _vm.styles.logo,
                    attrs: { src: logo.src },
                  })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }