var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { staticClass: "grey lighten-4" },
    [
      _c("page-section-title", { attrs: { title: "Tuttiの特徴" } }),
      _c("feature-boxes", {
        attrs: {
          index: 0,
          title: "迅速なラベリングシステム開発",
          body: "様々なラベリングタスクに対応した豊富なページテンプレートからラベリング画面の設計を始めることで、\n              ラベリングシステム開発の工数を大きく削減できます。<br>\n              HTML、CSS、JavaScriptのソースコード単位で開発を進めることもできるので、\n              カスタム仕様のラベリング画面の作成も思いのまま。",
          "fig-src": require("@/assets/web-figures/feature-figure-01.png"),
        },
      }),
      _c("feature-boxes", {
        attrs: {
          index: 1,
          title: "高機能なラベリング作業フロー設計",
          body: "最初にラベリングの練習画面を表示したり、定期的に作業者の\n              パフォーマンスをチェックするための質問を挟んだりなど、\n              ラベリング作業の流れを自由に決めることができます。<br>\n              フローチャート設計機能により、複数の画面のページ遷移を伴う\n              高機能なラベリング作業を、一つの画面の中で実現できます。",
          "fig-src": require("@/assets/web-figures/feature-figure-02.png"),
        },
      }),
      _c("feature-boxes", {
        attrs: {
          index: 2,
          title: "作業プラットフォーム連携機能で<br>簡単人海戦術",
          body: "Tuttiで開発したラベリングシステムは、管理画面での簡単な操作のみで、\n              作業者の集まる各種プラットフォームへすぐに配信できます。<br>\n              自社社員のラベリング人材が登録されたポータルサイトや、何万人ものワーカーが\n              集まるクラウドソーシングサイトへの外注に、特別な知識は一つも要りません。",
          "fig-src": require("@/assets/web-figures/feature-figure-03.png"),
        },
      }),
      _c("feature-boxes", {
        attrs: {
          index: 3,
          title: "スムーズなデータ分析・活用を<br>実現するAPI",
          body: "Tuttiのデータ領域に蓄積されたラベリング対象データやラベリング結果は、\n              PythonやJavaScriptなどのAPIを介して取得可能です。<br>\n              ラベリング対象データを自動で追加したり、作業者の回答をプッシュ通知で受け取ったりなど、\n              独自のアプリケーションとの相性も抜群です。",
          "fig-src": require("@/assets/web-figures/feature-figure-04.png"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }