var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      staticClass: "content",
      style: _vm.styles.content,
      attrs: { cols: _vm.$vuetify.breakpoint.forPC ? false : 12 },
    },
    [
      _c("img", {
        style: _vm.styles.num,
        attrs: { id: "num", src: _vm.numFigSrc },
      }),
      _c("p", {
        style: _vm.styles.title,
        attrs: { id: "title" },
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _c("p", {
        style: _vm.styles.body,
        attrs: { id: "body" },
        domProps: { innerHTML: _vm._s(_vm.body) },
      }),
      _c("img", { attrs: { id: "figure", src: _vm.figSrc } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }