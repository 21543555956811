var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    [
      _c("top-first-view-section"),
      _c("advantages-section"),
      _c("call-to-action-section"),
      _c("features-section"),
      _c("strength-section"),
      _c("customer-voices-section"),
      _c("customers-section"),
      _c("news-section"),
      _c("link-anchor", { attrs: { id: "contact" } }),
      _c("contact-section"),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }