var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { staticClass: "grey lighten-4" },
    [
      _c("page-section-title", { attrs: { title: "利用者の声" } }),
      _c("customer-voice-card", {
        staticClass: "mb-8",
        attrs: {
          title: "複雑なクラウドソーシングタスクが簡単に！",
          body: "Tuttiのおかげで、複雑なクラウドソーシングタスクを簡単に実施できました。従来は困難だった、ワーカへのタスク割当の制御も手軽に実現できます。Amazon Mechanical Turkとの連携がスムーズで、Tutti上で完結できる点も素晴らしいです。",
          "avatar-src": require("@/assets/images/customers/avatars/tsukuba-prof-baba.jpeg"),
          affiliation: "筑波大学システム情報系",
          name: "馬場 雪乃教授",
        },
      }),
      _c("customer-voice-card", {
        attrs: {
          title: "テンプレートやサポート対応に満足！",
          body: "機械学習関連の研究開発に向けてデータセットを用意するワークフローを確立したく、検証に至りました。想定しているデータやタスクについて入念なヒアリングの上で、バッチリなカスタムテンプレートをご用意いただけました。迅速なサポート対応も含め、安心して利用できるシステムだと感じています。",
          "avatar-src": require("@/assets/images/customers/avatars/cri-mw-kamuro.png"),
          affiliation: "株式会社CRI・ミドルウェア 開発本部",
          name: "家室 証様",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }