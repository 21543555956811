var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-4 pa-2" },
    [
      _c("div", { staticClass: "pa-4 pb-0 d-flex", attrs: { id: "header" } }, [
        _c("div", {
          style: _vm.styles.date,
          attrs: { id: "date" },
          domProps: { innerHTML: _vm._s(_vm.date) },
        }),
        _c("div", {
          style: _vm.styles.tag,
          attrs: { id: "tag" },
          domProps: { textContent: _vm._s(_vm.tagText) },
        }),
      ]),
      _c("v-card-title", [
        _c("div", {
          staticClass: "font-weight-bold",
          style: _vm.styles.title,
          attrs: { id: "title" },
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
      ]),
      _c("v-card-text", {
        style: _vm.styles.body,
        attrs: { id: "body" },
        domProps: { innerHTML: _vm._s(_vm.body) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }