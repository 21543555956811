var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { attrs: { id: "section" } },
    [
      _c("page-section-title", { attrs: { title: "Tutti開発の強み" } }),
      _c(
        "div",
        {
          class: _vm.$vuetify.breakpoint.forPC
            ? "d-flex justify-space-between"
            : "",
        },
        [
          _c("strength-card", {
            attrs: {
              title: "データラベリングの専門的な知見",
              body: "主にクラウドソーシングを用いた数千人規模のデータラベル収集や\n                  データ分析に多数の実績があります。ラベリングシステムの設計や\n                  運用に関する幅広い知見を強みに、製品開発やお客様サポートを行っています。",
              "fig-src": require("@/assets/web-figures/strength-01.png"),
            },
          }),
          _c("strength-card", {
            attrs: {
              title: "早稲田大学との共同研究",
              body: "Tuttiを開発する株式会社知能フレームワーク研究所（ifLab Inc.）は、\n                  早稲田大学知覚情報システム研究室から始まった大学発スタートアップ企業です。\n                  AIにおける共同研究でのツール活用・研究成果によるツール強化のサイクルを回し、開発を進めています。",
              "fig-src": require("@/assets/web-figures/strength-02.png"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }