var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      class: _vm.flexDirection,
      style: _vm.styles.wrapper,
      attrs: { align: "center" },
    },
    [
      _c(
        "v-col",
        {
          class: _vm.bodyColumnPadding,
          attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 },
        },
        [
          _c("p", {
            style: _vm.styles.title,
            attrs: { id: "title" },
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _c("p", {
            style: _vm.styles.body,
            domProps: { innerHTML: _vm._s(_vm.body) },
          }),
        ]
      ),
      _c(
        "v-col",
        {
          class: ["text-center", _vm.figureColumnPadding],
          attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 },
        },
        [_c("img", { style: _vm.styles.figure, attrs: { src: _vm.figSrc } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }